sl-color-picker {
  --swatch-size: 15px;
  --sl-border-radius-circle: 0;
}

sl-tooltip {
  --max-width: 700px;
  --sl-tooltip-background-color: rgb(0, 0, 0);
  --sl-tooltip-color: rgb(255, 255, 255);
  --sl-tooltip-padding: 1em;
  --sl-tooltip-font-size: calc(var(--font-size, 1rem) * 0.875);
  --sl-tooltip-font-family: inherit;
  --sl-tooltip-font-weight: inherit;
  --sl-tooltip-border-radius: 0; /*  max width need */
}

sl-icon-button {
  --sl-color-primary-700: var(--user-site-primary-main, rgb(0, 0, 0));
  --sl-color-primary-600: var(--user-site-primary-main, rgb(0, 0, 0));
}

sl-icon-button.ghost {
  color: rgb(153, 153, 153);
}

sl-icon-button.medium {
  font-size: 24px;
}

.wall-preview-color-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 38px;
  margin: auto;
  border-color: var(--user-site-border-color);
  border-style: var(--user-site-border-style);
  border-width: var(--user-site-border-width);
  border-radius: var(--user-site-border-radius);
}

.wall-preview-color-picker sl-color-picker::part(trigger) {
  align-self: start;
  width: 75px;
  height: 15px;
  margin-right: auto;
}

.wall-preview-color-picker sl-color-picker::part(trigger)::before {
  box-shadow: inset 0 0 0 1px var(--sl-input-border-color),
    inset 0 0 0 2px var(--sl-color-neutral-0);
}

.wall-scene-color-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: var(--user-site-button-height);
  margin: auto;
  border: 1px solid var(--wp-color-border-1);
  border-color: var(--user-site-border-color);
  border-style: var(--user-site-border-style);
  border-width: var(--user-site-border-width);
  border-radius: 0;
}

.wall-scene-color-picker:hover {
  border-color: var(--user-site-primary-main);
}

.wall-scene-color-picker sl-color-picker::part(trigger) {
  align-self: start;
  width: 75px;
  height: 15px;
  margin-right: auto;
}

.wall-scene-color-picker sl-color-picker::part(trigger)::before {
  box-shadow: none;
}

.canvas-border-color-medium-option {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  padding-top: 10px !important;
}

.canvas-border-color-medium-option sl-color-picker {
  width: 38px;
  height: 38px;
}

.canvas-border-color-medium-option sl-color-picker::part(trigger) {
  width: 38px;
  height: 38px;
}
